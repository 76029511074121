import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../../components/layout/NonStretchedImage';
import ContentWrapper from '../../../components/layout/ContentWrapper';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const CtaContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 90%;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.formTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.formTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Xero integration - Rotaready"
          description="A complete end-to-end workforce management solution for your business, bringing together the power of Rotaready employee scheduling with Xero payroll."
          url="integrations/xero"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="Xero and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">A complete end-to-end workforce management solution for your business, bringing together the power of Rotaready employee scheduling with Xero payroll.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">Our free integration automatically updates Xero when changes are made to employees and absence. And when it's time to run payroll, Rotaready exports worked hours at the relevant pay rates straight into your Xero timesheets.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://www.xero.com/signup/?xtid=x30rotaready" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Try Xero for free" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Make payroll a breeze" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Rotaready produces perfect timesheets ready for payroll by automatically combining your shifts with the times people clock in and out. Your rounding rules are applied too, trimming any early starts, adjusting late finishes or deducting breaks." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="With our Xero integration, you can feed these timesheets into Xero pay runs with a single click. Rotaready will inform Xero of the precise hours each employee worked and crucially, the pay rates that applied." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration will also update Xero with any absence and time off booked through Rotaready, keeping both systems up to date in real-time." />
              </TextBlock>

              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. We've written a handy article in our Knowledge Base to guide you through it." />
              </TextBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Learn how to enable the Xero integration"
                  render={props => <a {...props} href="https://help.rotaready.com/en/articles/4563978-xero-payroll-uk" />}
                />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About Xero" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Xero is world-leading online accounting software built for small business." />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Get a real-time view of your cashflow. Log in anytime, anywhere on your Mac, PC, tablet of phone to get a real-time view of your cash flow. It's small business accounting software that's simple, smart and occasionally magical." />
                    <TextListItem text="Run your business on the go. Use our mobile app to reconcile, send invoices, or create expense claims - from anywhere." />
                    <TextListItem text="Get paid faster with online invoicing. Send online invoices to your customers - and get updated when they're opened." />
                    <TextListItem text="Reconcile in seconds. Xero imports and categorises your latest bank transactions. Just click ok to reconcile." />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more or try Xero Accounting Software for free"
                  render={props => <a {...props} href="https://www.xero.com/" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <ContentWrapper>
            <CtaContainer>
              <TextBlock>
                <H2 uistyle="brand160" text="Ready to get started?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" uistyle="grey" text="See Rotaready and Xero in action with a live demo." />
              </TextBlock>

              <CtaButtons>
                <Link to="/demo">
                  <Button uistyle="primary" text="Request demo" size="lg" />
                </Link>

                <a href="https://www.xero.com/signup/?xtid=x30rotaready" target="_blank" rel="noopener noreferrer">
                  <Button uistyle="primary" text="Try Xero for free" ghost borderless size="lg" />
                </a>
              </CtaButtons>
            </CtaContainer>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/xero/hero-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 512, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
